
import {

	isMobile,
	isMobileOnly,
	isTablet,
	mobileModel,
	
} from 'mobile-device-detect'

export default {

	data	: function() { return {
	}},

	created	: function () {
	},

	methods	: {

		/**
		 * Mobile & tablettes
		 */

		is_touch() {
			return isMobile
		},

		is_phone() {
			return isMobileOnly
		},

		is_tablet() {
			return isTablet
		},

		is_iphone5() {
			return ( mobileModel == 'iPhone' && window.innerWidth == 320 )
		},
	
	}

}