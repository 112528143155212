<template>

	<div id="app" :class="{
		'device-phone' : is_phone(),
		'device-tablet' : is_tablet(),
		'device-touch' : is_touch(),
	}">

		<router-view v-if="authenticated" />

		<login v-if="!authenticated" />

		<div id="loader">
			<img src="@/assets/img/logo-random-ttfl.png" />
		</div>

		<!-- <div id="api_error">
			<div>Allo houston, problème de communcation avec le serveur...</div>
		</div> -->

	</div>

</template>

<script>

	import device from '@/mixins/device'

	export default {

		mixins	: [ device ],

		components	: {

			login	: () => import( '@/components/layout/login.vue' ),

		},

		computed	: {

			/** ---------------------------------------*\
			*---------------------------------------- **/

			authenticated() {
				return this.$store.state.user.authenticated
			},

		},

		methods	: {

			/** ---------------------------------------*\
			*---------------------------------------- **/

			logout() {
				this.$store.dispatch( 'user_logout' )
			},

		},

		mounted	: function() {

			if ( this.$cookies.get( 'user' ) && this.$cookies.get( 'user' ).authenticated === true ) {

				this.$store.state.user.authenticated	= true
				this.$store.dispatch( 'user/init' )

			} else {
				this.$store.state.user.authenticated	= false
			}

		}

	}
	
</script>

<style lang="scss">

	@import '@/assets/scss/style.scss';

</style>
