import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use( VueRouter )

const routes	= [
	{
		path	: '/team/:date?',
		name	: 'team',
		component	: () => import( '../views/team.vue' ),
		meta : {
			title : 'Random TTFL - Team',
		},
	},
	{
		path	: '/:date?',
		name	: 'day',
		component	: () => import( '../views/day.vue' ),
		meta	: {
			title	: 'Random TTFL - Day',
		},
	},
	{
		path	: '/user/:username?',
		name	: 'user',
		component	: () => import( '../views/user.vue' ),
		meta	: {
			title	: 'Random TTFL - User',
		},
	},
]

const router	= new VueRouter({
	mode	: 'history',
	base	: process.env.BASE_URL,
	routes,
})



router.afterEach(( to ) => {

	Vue.nextTick( () => {
		document.title	= to.meta.title ? to.meta.title : 'Random TTFL'
	})

})

export default router
