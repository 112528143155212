import Vue from 'vue'
import Vuex from 'vuex'

import players from './players.store'
import team from './team.store'
import user from './user.store'

Vue.use( Vuex )

export default new Vuex.Store({

	state: {
	},

	mutations: {
	},

	actions: {
	},

	modules: {
		players, team, user
	}

})
