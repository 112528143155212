
import api from '@/api'

export default {

	namespaced	: true,
	state	: () => ({
		
		id	: false,
		picks	: [],
		day	: false,

		authenticated	: false,

	}),

	mutations	: {

		/**
		 */
		
		update_user( state, user ) {

			if ( user.id ) {
				state.id	= user.id
			}

			if ( user.picks ) {
				state.picks	= user.picks
			}

			if ( user.day ) {
				state.day	= user.day
			}

			localStorage.setItem( 'store.user', state.id )

		},

		/**
		 */

		update_pick_date( state, datas ) {

			var picks	= state.picks

			state.picks	= []

			/**
			 * S'il existe, MAJ du pick du jour de l'user
			 */

			var pick_founded	= picks.filter(function( pick ) {

				if ( pick.date == datas.date ) {
					pick.player_id	= datas.player_id
					return pick
				}

			})

			/**
			 * Si pas de pick du jour, on l'ajoute
			 */

			if ( pick_founded.length == 0 ) {

				picks.push({
					date : datas.date,
					player_id : datas.player_id,
				})

			}

			state.picks	= picks

		},

		/**
		 * Déconnexion
		 * Suppression des données utilisateur
		 */

		user_logout( state ) {

			state.user	= {
				authenticated	: false,
			}

		},

	},

	actions	: {

		/**
		 */

		init( state ) {
		// init( state, { callback } ) {

			if ( localStorage.getItem( 'store.user' ) ) {

				state.dispatch( 'api_picks', {
					user_id	: localStorage.getItem( 'store.user' ),
				})

			}

		},

		/**
		 */

		async api_picks({ commit }, { user_id, callback } ) {

			commit( 'user/update_user', {
				id	: user_id,
			}, { root : true })

			api.get( 'ttfl_user/picks', {
				id	: user_id,
			}).then(( json ) => {

				if ( callback )	callback( json )

				commit( 'user/update_user', {
					picks	: json,
				}, { root : true })

			})

		},

		/**
		 */

		async api_set_pick({ commit }, datas ) {

			api.post( 'ttfl_user/set_pick', datas ).then(( json ) => {
				
				if ( json.success == true ) {

					commit( 'user/update_pick_date', {

						player_id	: datas.player_id,
						date	: datas.date,
						
					}, { root : true })

					commit( 'team/update_user_pick', {
					
						date	: datas.date,
						user_id	: datas.id,
						player_id	: datas.player_id,
	
					}, { root : true })

					if ( datas.callback && typeof datas.callback == 'function' ) {
						datas.callback()
					}

				} else {
					console.error( 'user.store : Erreur ajout du pick' )
				}

			})

		},

		/**
		 * Déconnexion
		 */

		user_logout({ commit }) {
			commit( 'user_logout' )
		},

	},

	modules : {
	}

}
