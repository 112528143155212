import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'regenerator-runtime/runtime'

import VueCookies from 'vue-cookies'
import VueHtml2Canvas from 'vue-html2canvas'

Vue.use( VueHtml2Canvas )
Vue.use( VueCookies )

const moment	= require( 'moment' )
require( 'moment/locale/fr' )

Vue.use (require('vue-moment'), {
	moment
})

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import {
// 	faDownload,
// 	faPeopleArrows,
// 	faTimes,
// 	faUserCheck,
// 	faSortUp,
// 	faSortDown
// } from '@fortawesome/free-solid-svg-icons'

// library.add(
// 	faDownload,
// 	faPeopleArrows,
// 	faTimes,
// 	faUserCheck,
// 	faSortUp,
// 	faSortDown,
// )

// Vue.component( 'font-awesome-icon', FontAwesomeIcon )

Vue.config.productionTip = false

window.ttfl_start_day	= '2021-10-19'

new Vue({
	store,
	router,
	// VueHtml2Canvas,
	render	: h => h( App )
}).$mount( '#app' )
