/** ----------------------------------------------------*\
 *
 * @class player
 *
 * - 
 *
*---------------------------------------------------- **/

import Tools from '@/class/Tools.class'


/** ---------------------------------------*\
	Class
*---------------------------------------- **/

export default class Player extends Tools {

	constructor( player_api ) {
		
		super()

		this.id	= player_api.player_id
		this.vue	= {}
		this.team_roster	= []
		this.injury	= {
			class	: 'notready',
			details	: 'Non défini',
		}

		var _this	= this

		Tools.jsonEach( player_api, function( player, key ) {
			_this[key]	= player
		})

		this.set_vue_datas()

	}

	set_vue_datas() {

		var _this	= this

		Tools.jsonEach( this.ttfl.games, function( game ) {
			game.ttfl	= Tools.ttfl_score_color( game.ttfl_score, 75, 25 )
		})

		Tools.jsonEach( this.ttfl.last_3vs.games, function( game, key ) {
			_this.ttfl.last_3vs.games[key].ttfl	= Tools.ttfl_score_color( game.ttfl_score )
		})

		Tools.jsonEach( this.ttfl.last_5.games, function( game, key ) {
			_this.ttfl.last_5.games[key].ttfl	= Tools.ttfl_score_color( game.ttfl_score )
		})

		Tools.jsonEach( this.ttfl.b2b_games.games, function( game, key ) {
			_this.ttfl.b2b_games.games[key].ttfl	= Tools.ttfl_score_color( game.ttfl_score )
		})



		/**
		 * Fix un bug, le tableau last_5 étant un objet
		 */

		if ( !Array.isArray( this.ttfl.last_5.games ) ) {

			var tmp_last5	= this.ttfl.last_5.games
			this.ttfl.last_5.games	= []

			Tools.jsonEach( tmp_last5, function( game ) {
				_this.ttfl.last_5.games.push( game )
			})

			this.ttfl.last_5.games.sort(function( a, b ) {

				var time_a	= new Date( a.date ).getTime()
				var time_b	= new Date( b.date ).getTime()

				return time_b - time_a

			})

		}



		this.ttfl.index_vs	= this.ttfl_momentum( this.ttfl.index_vs )
		this.ttfl.index_def	= this.ttfl_momentum( this.ttfl.index_def )
		this.ttfl.index_b2b	= this.ttfl_momentum( this.ttfl.b2b_games.index )
		this.ttfl.homeaway	= this.ttfl_momentum( this.ttfl.index_homeaway )
		this.ttfl.index_stab	= this.ttfl_index_html( this.ttfl.index_stab, 20.1, 0.1 )

		this.ttfl.mom	= this.ttfl_momentum( this.ttfl.momentum )
		this.ttfl.estimation	= this.ttfl_index( this.ttfl.estimation, 25, 70 )
		this.set_index()
		
		this.ttfl.heat_map	= this.ttfl_heat_map()



		this.team	= Tools.search_team( this.team_id )
		this.team_opp	= Tools.search_team( this.team_opp_id )

		this.vue	= {

			id 	: this.player_id,
			team_id	: this.team.teamId,
			team_logo	: this.team.logo,
			fullname	: this.fname.charAt(0) + '. ' + this.lname,
			
			ttfl_l10_av_color	: Tools.ttfl_score_color( this.ttfl.last_10days.avg, 60, 25 ),
			ttfl_l30_av_color	: Tools.ttfl_score_color( this.ttfl.last_30days.avg, 60, 25 ),
			ttfl_average	: this.ttfl.avg_season,
			ttfl_av_color	: Tools.ttfl_score_color( this.ttfl.avg_season, 60, 25 ),

			team_vs_id	: this.team_opp.team_id,
			team_vs_logo	: this.team_opp.logo,
			home_game	: this.home_game,

			injury_status	: {
				class	: 'notready',
				title	: 'Non défini',
			},

		}

		this.sorting_values	= {

			'avg_season'	: this.ttfl.avg_season,
			'l10_avg'	: this.ttfl.last_10days.avg,
			'l30_avg'	: this.ttfl.last_30days.avg,

			'b2b'	: this.ttfl.index_b2b.float_value,
			'homeaway'	: this.ttfl.index_homeaway,
			'index_vs'	: this.ttfl.index_vs.value,
			'index_def'	: this.ttfl.index_def.value,
			'momentum'	: this.ttfl.mom.value,
			'stab'	: this.ttfl.index_stab.float_value,
			'estimation'	: this.ttfl.estimation.value,
			'index'	: this.ttfl.index.value,
			
		}

	}

	/**
	 */

	set_momentum() {

		var value	= this.ttfl.last_30days.avg + ( this.ttfl.last_10days.avg * 2 )
		value	= value / 3
		value	= value - this.ttfl.avg_season

		this.ttfl.mom	= this.ttfl_momentum( value )

	}

	/**
	 */

	set_estimation() {

		var avg	= 0,
		index_values	= []

		index_values.push( this.ttfl.index_vs.float_value )
		index_values.push( this.ttfl.index_def.float_value )
		index_values.push( this.ttfl.mom.float_value )
		index_values.push( this.ttfl.homeaway.float_value )

		avg	= index_values.reduce( ( a, b ) => a + b, 0 )
		avg	= avg / index_values.length

		this.ttfl.estimation	= this.ttfl_index( parseFloat( this.ttfl.avg_season ) + avg, 25, 70 )

	}

	/**
	 */

	set_index() {

		var index_values	= [],
			avg	= 0



		index_values.push( this.ttfl.index_vs.float_value )
		index_values.push( this.ttfl.index_def.float_value )
		index_values.push( this.ttfl.mom.float_value )
		index_values.push( this.ttfl.homeaway.float_value )

		avg	= index_values.reduce( ( a, b ) => a + b, 0 )
		avg	= avg / index_values.length

		this.ttfl.index	= this.ttfl_index( avg, 1, 15 )

	}

	/**
	 */

	ttfl_heat_map() {

		var _this	= this
		var scores	= {
				'm20'	: 0,
				'm30'	: 0,
				'm40'	: 0,
				'm50'	: 0,
				'50p'	: 0,
			}

		if ( this.ttfl.last_30days.games.length == 0 ) {
			return scores
		}


		this.ttfl.last_30days.games.forEach(function( game ) {

			if ( game.ttfl_score < 20 ) {
				scores['m20']++
			} else if ( game.ttfl_score >= 20 && game.ttfl_score < 30 ) {
				scores['m30']++
			} else if ( game.ttfl_score >= 30 && game.ttfl_score < 40 ) {
				scores['m40']++
			} else if ( game.ttfl_score >= 40 && game.ttfl_score < 50 ) {
				scores['m50']++
			} else {
				scores['50p']++
			}

		})

		Tools.jsonEach( scores, function( score, key ) {
			scores[key]	= _this.ttfl_index( Math.round( ( score / _this.ttfl.last_30days.games.length ) * 100 ), 50, 100 )
		})

		return scores

	}

}