/**
 * 
 * Class Tools
 * 
 */

import teams_config from '@/assets/json/teams.config.json'

import moment from 'moment'
moment.locale( 'fr' )

export default class Tools {

	/**
	 */

	static jsonEach( json, action ) {

		Object.entries( json ).forEach(([ key, value ]) => {
			action( value, key )
		})

	}

	/**
	 */

	static add_class( selector, classes ) {

		document.querySelectorAll( selector ).forEach(function( el ) {
			el.classList.add( classes )
		})

	}

	/**
	 */

	static remove_class( selector, classes ) {

		document.querySelectorAll( selector ).forEach(function( el ) {
			el.classList.remove( classes )
		})

	}
	
	/**
	 */

	static get_data( selector, key ) {

		return document.querySelectorAll( selector ).getAttribute( key )
	}

	/**
	 */

	static search_team( team_id ) {

		var the_team	= false;

		teams_config.forEach(function( team ) {

			if( team.teamId == team_id ) {
				the_team	= team
				return
			}

		})

		return the_team

	}

	/**
	 */

	static ttfl_score_color( score, score_max = 70, score_median = 25 ) {

		// var score_max		= 70,
		// 	score_median	= 25,
		var color			= '#ff0000',
			opacity			= 1,
			diff			= 0,
			theclass		= 'good';

		score	= Math.round( score );

		// if ( season_average && season_average === true ) {
		// 	score_max		= 60;
		// 	score_median	= 25;
		// }

		if ( score >= score_median ) {

			diff	= ( score - score_median );

		} else {

			color		= '#105a8e';
			theclass	= 'notgood';
			diff		= ( score_median - score );
			
		}

		opacity		= diff / ( score_max - score_median );
		opacity		+= 0.1;

		return {
			value	: score,
			color	: color,
			opacity	: opacity,
			class	: theclass,
		};

	}

	/**
	 */

	ttfl_momentum( value, max = 20 ) {

		var opacity	= 1,
			theclass	= 'notgood',
			final_value	= '-',
			orig_value	= 0;



		if ( value ) {

			orig_value	= value;

			final_value	= value.toFixed( 1 );

			if ( value < 0 ) {
				value	= value * -1;
			} else {
				theclass		= 'good';
				final_value		= '+' + value.toFixed( 1 );
			}

		} else {
			value	= 0;
		}

		opacity	= value / max;

		return {
			'float_value'	: orig_value,
			'value'			: final_value,
			'opacity'		: opacity,
			'class'			: theclass,
		}

	}

	/**
	 */

	ttfl_index_html( value, min = -20, max = 20, median ) {

		var opacity	= 1,
			theclass	= 'good',
			orig_value	= value,
			final_value	= Math.round( value )



		if ( typeof median != 'undefined' ) {
			
			/**
			 * Dans le cas où on a un index descendant
			 */

			if ( min > max ) {

				opacity	= ( value - ( median - max ) ) / median
				opacity	= ( Math.sign( opacity ) == -1 ) ? opacity * -1 : opacity

			} else {

				opacity	= value / max

			}



			if ( min > max ) {

				median	= median * -1

				if ( value > median ) {
					theclass	= 'notgood'
					opacity		= 1 - opacity
				}

			} else {

				if ( value < median ) {
					theclass	= 'notgood'
				}

			}

		} else {

			/**
			 * Dans le cas où on a un index descendant
			 */

			if ( min > max ) {
				opacity	= ( value - ( min - max ) ) / min
				opacity	= ( Math.sign( opacity ) == -1 ) ? opacity * -1 : opacity
			} else {
				opacity	= value / max
			}

		}



		if ( opacity == 0 ) {
			opacity	= 0.1
		}

		return {
			'float_value'	: orig_value,
			'value'	: final_value,
			'opacity'	: opacity,
			'class'	: theclass,
		}

	}


	/**
	 */

	ttfl_index( value, median = 0, max = 25 ) {

		var opacity	= 1,
			theclass	= 'good',
			orig_value	= 0,
			final_value	= Math.round( value );



		orig_value	= value;

		if ( value < median ) {
			theclass	= 'notgood';
		}

		opacity	= value / max;

		if ( opacity == 0 ) {
			opacity	= 0.1;
		}

		return {
			'float_value'	: orig_value,
			'value'	: final_value,
			'opacity'	: opacity,
			'class'	: theclass,
		}

	}

	/**
	 */

	get_deck_number( date ) {

		var tuesday	= moment( date.valueOf() ).isoWeekday( 2 )	// Mardi car la saison a commencé un mardi

		return ( tuesday.diff( moment( window.ttfl_start_day ), 'weeks' ) + 1 )

	}

}