
import Player from '@/class/Player.class'

export default {

	namespaced	: true,

	state	: () => ({

		originals	: [], 
		results	: [], 
		injury_api_error	: false,
		injuries	: [],
		
	}),

	mutations	: {

		/**
		 */

		set_players_from_api( state, datas ) {

			state.results	= []
			state.injuries	= datas.injuries


			datas.players.forEach(function( player ) {

				if ( !player.ttfl ) return

				var player_f	= new Player( player )

				datas.games.forEach(function( game ) {

					if ( game.hteam_id == player_f.team_id ) {
						player_f.team_roster	= game.hteam_roster
					} else if ( game.vteam_id == player_f.team_id ) {
						player_f.team_roster	= game.vteam_roster
					}

				})

				var injury	= datas.injuries.find( ( inj_player => inj_player.player_id == player_f.id ) )

				if ( injury ) {

					player_f.injury	= {
						class	: injury.formated,
						details	: injury.details,
					}

				}

				state.results.push( player_f )
				state.originals.push( player_f )

			})

		},

		/**
		 */

		// set_player_injury_status( state, datas ) {

		// 	state.results.some(function( player ) {

		// 		if ( player.id == datas.player_id )	player.injury	= {
		// 			class	: datas.status.formated,
		// 			details	: datas.status.details,
		// 		}

		// 		return player.id === datas.player_id
				
		// 	})

		// 	state.originals.some(function( player ) {

		// 		if ( player.id == datas.player_id )	player.injury	= {
		// 			class	: datas.status.formated,
		// 			details	: datas.status.details,
		// 		}

		// 		return player.id === datas.player_id
				
		// 	})

		// },

		/**
		 */

		remove_player( state, player_id ) {

			state.results	= state.results.filter(function( player ) { 

				if ( typeof player != 'undefined' && player.id != player_id ) {
					return player
				}
				
			})

			state.originals	= state.originals.filter(function( player ) { 

				if ( typeof player != 'undefined' && player.id != player_id ) {
					return player
				}
				
			})

		},

	},

	getters	: {
		
		get_players	: ( state ) => {
			return state.originals
		},

		get_player	: ( state ) => ( player_id ) => {
			return state.results.find( player => player.id === player_id )
		},

	},

	actions	: {
	},

	modules: {
	}

}
