/** ----------------------------------------------------*\
 *
 * @class User
 *
 * - 
 *
*---------------------------------------------------- **/

import moment from 'moment'
moment.locale( 'fr' )

import Tools from '@/class/Tools.class'


/** ---------------------------------------*\
 * Class
*---------------------------------------- **/

export default class User extends Tools {

	constructor( user_api ) {

		super()

		var _this	= this

		Tools.jsonEach( user_api, function( value, key ) {
			_this[key]	= value
		})

		if ( this.picks.length > 0 ) {

			this.picks.forEach( pick => {
				pick.score	= parseInt( pick.score )
			})

		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	get_ranking( date ) {
		return this.rankings.find( ranking => date == ranking.date )
	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	get_past_picks( date_end, date_start = false ) {

		return this.picks.filter( pick => (
			pick.score && 
			( !date_start || ( date_start && moment( pick.date ).valueOf() >= date_start.valueOf() ) ) && 
			moment( pick.date ).valueOf() < date_end.valueOf()
		))

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	get_past_rankings( date ) {
		return this.rankings.filter( ranking => ( ranking.value && moment( ranking.date ).valueOf() < moment( date ).valueOf() ) )
	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	get_picks_average( date_end, date_start = false ) {

		var picks	= this.picks.filter( pick => (
			pick.score && 
			( !date_start || ( date_start && moment( pick.date ).valueOf() >= date_start.valueOf() ) ) && 
			moment( pick.date ).valueOf() < date_end.valueOf()
		))

		return Math.round( ( picks.reduce(( total, pick ) => total + pick.score, 0 ) / picks.length ) * 100 ) / 100

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	get_best_pick( date_end, date_start = false ) {

		var picks	= this.picks.filter( pick => (
			pick.score && 
			( !date_start || ( date_start && moment( pick.date ).valueOf() >= date_start.valueOf() ) ) && 
			moment( pick.date ).valueOf() < date_end.valueOf()
		))

		return picks.reduce(function( prev_pick, pick ) {
			return ( prev_pick.score > pick.score ) ? prev_pick : pick
		})

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	get_low_pick( date_end, date_start = false ) {

		var picks	= this.picks.filter( pick => (
			pick.score && 
			( !date_start || ( date_start && moment( pick.date ).valueOf() >= date_start.valueOf() ) ) && 
			moment( pick.date ).valueOf() < date_end.valueOf()
		))

		return picks.reduce(function( prev_pick, pick ) {
			return ( prev_pick.score < pick.score ) ? prev_pick : pick
		})

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	picks_count( min = false, max = false ) {

		var picks	= this.picks.filter( pick => (
			pick.score && 
			( !min || ( min && pick.score >= min ) ) && 
			( !max || ( max && pick.score <= max ) ) 
		))

		return picks.length

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	get_chart_color( hover = false ) {

		var id	= parseInt( this.id )
		var color	= 'rgb( 40, 223, 153 )'

		switch ( id ) {
			case 1 : color = 'rgba( 176, 111, 253, '; break;
			case 2 : color = 'rgba( 242, 142, 0, '; break;
			case 3 : color = 'rgba( 202, 54, 128, '; break;
			case 4 : color = 'rgba( 188, 0, 0, '; break;
			case 5 : color = 'rgba( 16, 90, 142, '; break;
			case 6 : color = 'rgba( 86, 175, 215, '; break;
			case 7 : color = 'rgba( 229, 206, 64, '; break;
			case 8 : color = 'rgba( 40, 149, 120, '; break;
			case 9 : color = 'rgba( 226, 184, 172, '; break;
			case 10 : color = 'rgba( 40, 223, 153, '; break;
		}


		// if ( !hover ) {}

		return !hover ? color + '1 )' : color + '0.7 )';

	}

}